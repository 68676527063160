import getConfig from 'next/config'
import config from '@public-config'
import * as Sentry from '@sentry/node'
import { RewriteFrames } from '@sentry/integrations'

export function initSentry() {
  const distDir = `${getConfig().serverRuntimeConfig.rootDir}/.next`

  if (!config.sentry.enabled) return

  Sentry.init({
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next')
          return frame
        },
      }),
    ],
    dsn: config.sentry.dsn,
    environment: config.popdailyEnv,
    release: config.gitSha || 'development',
    allowUrls: [
      /https?:\/\/(static|static-staging|beta|staging|www)\.popdaily\.com.tw/,
      /\/app\/web\//, // 從 GKE logger 發現，SSR error 的 url 會長這樣
    ],
    beforeSend(event, hint) {
      const error = hint.originalException
      if (
        error &&
        error.message &&
        error.message.match(/(Illegal invocation|SecurityError: Blocked a frame with origin|)/i)
      ) {
        return null
      }
      return event
    },
  })
}
